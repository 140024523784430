import "../styles/globals.css";
import type { AppProps } from "next/app";
import Head from 'next/head';
import { Footer } from "../components/Footer";
import Navbar from "../components/Navbar";
import MainNavigation from "@/components/MainNavigation";
import { ClinicNap } from "../components/ClinicNap";
// import GoogleAnalytics from '@/components/GoogleAnalytics';
import { MDXProvider } from '@mdx-js/react';
import { CustomLink } from "@/components/CustomLink";


const components = {
  a: CustomLink,
}

function MyApp({ Component, pageProps }: AppProps) {

  return (
    <>
     <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
        {/* <GoogleAnalytics /> */}
        
      </Head>
      <ClinicNap />
      {/* <Navbar /> */}
      <MainNavigation />
      <MDXProvider>
        <Component {...pageProps} />
      </MDXProvider>
      <Footer />
    </>
  );
}

export default MyApp;